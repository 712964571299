import React, { useState, useEffect } from 'react';
import { FormattedMessage, Link, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import CodeIcon from '@material-ui/icons/Code';
import LockOutlined from '@material-ui/icons/LockOutlined';
import DoneIcon from '@material-ui/icons/Done';
// core components
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';

import Button from 'components/CustomButtons';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import {
  signupStart,
  confirmSignUpStart,
} from '@allconnect/store/lib/user/user.actions';
import {
  selectAppLoading,
  selectStatus,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage';

const useStyle = makeStyles(loginPageStyle);

const initValues = {
  email: '',
  password: '',
  password1: '',
  agreeUserTerm: false,
  code: '',
  referralCode: '',
};

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('E-mail is required'),
  password: yup
    .string()
    .min(6)
    .required('Password is required'),
  password1: yup
    .string()
    .min(6)
    .required('Password is required'),
  code: yup
    .string()
    .min(6)
    .max(6),
  agreeUserTerm: yup.bool().required('You must agree user term'),
  referralCode: yup.string(),
});

// Register component
const FreeRegister = ({
  currentUser,
  appStatus,
  loading,
  reset,
  signUpStart,
  confirmSignUpStart,
}) => {
  const classes = useStyle();
  const [stage, setStage] = useState(UserActionTypes.SIGNUP_START);

  const [cardAnimation, setAnimation] = useState('cardHidden');

  useEffect(() => {
    let i = false;
    setTimeout(function() {
      if (!i) setAnimation('');
    }, 800);
    return () => (i = true);
  }, []);

  useEffect(() => {
    if (currentUser && stage === UserActionTypes.SIGNUP_START) {
      navigate('/app/home');
    }

    if (appStatus === UserActionTypes.SIGNUP_SUCCESS) {
      setStage(UserActionTypes.SIGNUP_SUCCESS);
    }
    if (appStatus === UserActionTypes.SIGNUP_SEND) {
      setStage(UserActionTypes.SIGNUP_SEND);
    }
    if (appStatus === UserActionTypes.SIGNUP_SUCCESS) {
      setStage(UserActionTypes.SIGNUP_SUCCESS);
    }
  }, [appStatus]);

  const handleSignUp = values => {
    if (stage === UserActionTypes.SIGNUP_START) {
      const { email, password, referralCode } = values;
      signUpStart({ type: 'free', email, password, referralCode });
    }
    if (stage === UserActionTypes.SIGNUP_SEND) {
      const { email, code, password } = values;
      confirmSignUpStart({ email, password, code });
    }
  };

  return (
    <Card className={classes[cardAnimation]}>
      <Formik
        initialValues={initValues}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleSignUp(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form>
            <CardHeader color="success" className={classes.cardHeader}>
              <h1 className={classes.cardTitle}>
                <FormattedMessage
                  id="register.title"
                  defaultMessage="Create Account"
                />
              </h1>
              {stage === UserActionTypes.SIGNUP_START && (
                <h3>
                  <FormattedMessage
                    id="register.start"
                    defaultMessage="Enter email and password below"
                  />
                </h3>
              )}
              {stage === UserActionTypes.SIGNUP_SEND && (
                <h3>
                  <FormattedMessage
                    id="register.checkEmail"
                    defaultMessage="Check your email and enter verification code"
                  />
                </h3>
              )}
            </CardHeader>
            {stage === UserActionTypes.SIGNUP_START && (
              <>
                <CardBody>
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="register.email"
                        defaultMessage="Your Email"
                      />
                    }
                    id="email"
                    error={touched.email && errors.email ? true : false}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      // autoFocus: true,
                      type: 'email',
                      name: 'email',
                      error: errors.email && touched.email ? true : false,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.email,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="register.password"
                        defaultMessage="Password"
                      />
                    }
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      error: errors.password && touched.password,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password,
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="register.confirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    }
                    id="password1"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password1',
                      error: errors.password1 && touched.password1,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password1,
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.password !== '' &&
                            values.password === values.password1 && (
                              <DoneIcon />
                            )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="register.referralCode"
                        defaultMessage="Referral Code (optional)"
                      />
                    }
                    id="referralCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      name: 'referralCode',
                      error: errors.referralCode && touched.referralCode,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.referralCode,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CodeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      name="agreeUserTerm"
                      checked={values.agreeUserTerm}
                      onChange={handleChange}
                      value={values.agreeUserTerm}
                      color="primary"
                    />
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      align="center"
                      display="block"
                    >
                      <FormattedMessage
                        id="register.agreeTo"
                        defaultMessage="Agree to our  "
                      />

                      <Link to="/terms-of-use" className={classes.link}>
                        <FormattedMessage
                          id="register.termOfUse"
                          defaultMessage="Terms Of Use"
                        />
                      </Link>
                    </Typography>
                  </div>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      disabled={
                        !values.email ||
                        values.password === '' ||
                        values.password !== values.password1 ||
                        !values.agreeUserTerm ||
                        isSubmitting
                      }
                      onClick={e => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                      color="google"
                      size="lg"
                      round
                      endIcon={<SendIcon />}
                    >
                      <FormattedMessage
                        id="register.submit"
                        defaultMessage="Submit"
                      />
                    </Button>
                    <Button
                      color="transparent"
                      onClick={() => {
                        navigate('/login');
                      }}
                      className={classes.text}
                    >
                      <FormattedMessage
                        id="register.login"
                        defaultMessage="Already have an account? Log In"
                      />
                    </Button>
                  </div>
                </CardFooter>
              </>
            )}
            {stage === UserActionTypes.SIGNUP_SEND && (
              <>
                <CardBody>
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="register.verification"
                        defaultMessage="Verification Code"
                      />
                    }
                    id="code"
                    error={touched.code && errors.code ? true : false}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      name: 'code',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.code,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CodeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    disabled={!values.email || !values.code || isSubmitting}
                    onClick={e => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    type="submit"
                    color="google"
                    size="lg"
                    round
                    endIcon={<SendIcon />}
                  >
                    <FormattedMessage
                      id="register.submit"
                      defaultMessage="Submit"
                    />
                  </Button>
                </CardFooter>
              </>
            )}
            {stage === UserActionTypes.SIGNUP_SUCCESS && (
              <>
                <CardBody>
                  <div className={classes.link} style={{ textAlign: 'center' }}>
                    <h3>
                      <FormattedMessage
                        id="register.signUpCompleted"
                        defaultMessage="Sign up completed! Click below to access My Account and get connected now!"
                      />
                    </h3>
                  </div>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      reset();
                      navigate('/app/home');
                    }}
                    type="submit"
                    color="google"
                    size="lg"
                    round
                  >
                    <FormattedMessage
                      id="register.accessMyAccount"
                      defaultMessage="Access My Account"
                    />
                  </Button>
                </CardFooter>
              </>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  loading: selectAppLoading,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  signUpStart: ({ email, type, password, referralCode }) =>
    dispatch(signupStart({ email, type, password, referralCode })),
  confirmSignUpStart: ({ email, password, code }) =>
    dispatch(confirmSignUpStart({ email, password, code })),
  reset: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeRegister);
