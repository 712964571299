import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// React icons
// core components
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage';
import Layout from 'components/Layout';
import image from 'assets/img/login.jpg';
import RegisterForm from './RegisterForm';

const useStyles = makeStyles(loginPageStyle);

const RegisterPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <RegisterForm />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPage;
